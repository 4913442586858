import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-white text-center" style={{height: '100vh', backgroundColor: '#0E94C3'}}>
      <img src='/ohowhite.png' alt='' style={{height: '200px'}} />
      <h2>OHOINDIA LIFE TECH PRIVATE LIMITED</h2>
      <h4>Comming Soon...</h4>
    </div>
  );
}

export default App;
